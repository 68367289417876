import { TableCell } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AddIconButton } from '../../../../../../components/common/button/CustomIconButton';

const ProcessProductAuditStatus = ({
  item,
  letter,
  tooltip,
}: {
  item: any;
  letter: string;
  tooltip: string;
}) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (item[`${letter}_part_id`] && item._id) {
      axios
        .get(
          `/api/processProductAuditRoute/isProcessProductExits/${item[`${letter}_part_id`]}/${item._id}?monthYear=${item.dueDate}`,
        )
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [item[`${letter}_part_id`], item._id]);
  return (
    <>
      <TableCell align="center">
        <span
          style={{
            background: data.count === 0 ? 'white' : 'green',
            padding: '15px 0px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {item[`${letter}_part_id`] && (
            <AddIconButton
              color={data.count !== 0 ? '#fff' : ''}
              tooltipTitle={tooltip}
              onClick={() => {
                window.location.href = `https://slf.aqua.mushinlabs.com/createProcessProductAudit/${item[`${letter}_part_id`]}?checklist_id=${item._id}&monthYear=${item.dueDate}&partNumber=${item[`${letter}_part_no`]}&partName=${item[`${letter}_part_name`]}&isView=false&date=${item.doneDate}`;
              }}
            />
          )}
        </span>
      </TableCell>
    </>
  );
};

export default ProcessProductAuditStatus;
